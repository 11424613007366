var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "close-on-click-modal": false,
        width: "50%",
        top: "7vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [
          _c("i"),
          _c("span", { domProps: { textContent: _vm._s(_vm.StuDialogTitle) } }),
        ]
      ),
      _c(
        "el-form",
        {
          ref: "formData",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { label: "用户名：", prop: "loginName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入用户名", disabled: _vm.isWeb },
                    model: {
                      value: _vm.formData.loginName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "loginName", $$v)
                      },
                      expression: "formData.loginName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "姓名：", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入姓名" },
                    model: {
                      value: _vm.formData.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "name", $$v)
                      },
                      expression: "formData.name",
                    },
                  }),
                ],
                1
              ),
              this.stuData === null
                ? [
                    _c(
                      "el-form-item",
                      { attrs: { label: "初始密码：", prop: "pwd" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder:
                              "请输入初始密码（密码包含大小写字母、特殊符号、数字三种即可）",
                            "show-password": "",
                            "auto-complete": "new-password",
                          },
                          model: {
                            value: _vm.formData.pwd,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "pwd", $$v)
                            },
                            expression: "formData.pwd",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : [
                    _c(
                      "el-form-item",
                      { attrs: { label: "修改密码：" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入新密码、为空密码不变",
                            "show-password": "",
                            "auto-complete": "new-password",
                          },
                          model: {
                            value: _vm.formData.pwd,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "pwd", $$v)
                            },
                            expression: "formData.pwd",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
              _c(
                "el-form-item",
                { attrs: { label: "手机号：" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入手机号" },
                    model: {
                      value: _vm.formData.tel,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "tel", $$v)
                      },
                      expression: "formData.tel",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "学员类型" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "mini", disabled: _vm.isWeb },
                      on: { input: _vm.seleType },
                      model: {
                        value: _vm.formData.stuType,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "stuType", $$v)
                        },
                        expression: "formData.stuType",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "试听学员" } }),
                      _c("el-radio-button", { attrs: { label: "正式学员" } }),
                    ],
                    1
                  ),
                  _vm.formData.stuType === "试听学员"
                    ? _c("el-date-picker", {
                        staticStyle: { "margin-left": "25px" },
                        attrs: {
                          type: "datetime",
                          disabled: _vm.isWeb,
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          placeholder: "试听学员账户失效日期",
                        },
                        model: {
                          value: _vm.expiresTime,
                          callback: function ($$v) {
                            _vm.expiresTime = $$v
                          },
                          expression: "expiresTime",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "所属校区：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择校区" },
                      on: { change: _vm.getSchoolValue },
                      model: {
                        value: _vm.formData.campus_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "campus_id", $$v)
                        },
                        expression: "formData.campus_id",
                      },
                    },
                    _vm._l(_vm.schoolArray, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { type: "primary" },
              on: { click: _vm.saveForm },
            },
            [_vm._v("确 定")]
          ),
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { type: "info" },
              on: { click: _vm.close },
            },
            [_vm._v("取 消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }