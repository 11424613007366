<template>
  <el-row class="table">
    <span class="title">学员管理</span>
    <el-row class="row-bg">
      <el-input
        placeholder="输入搜索用户名、姓名关键字"
        v-model.trim="inputVal"
        class="tb-search-input"
        @keyup.enter.native="search"
      >
        <el-select v-model="select" slot="prepend" placeholder="请选择" style="width: 120px;">
          <el-option label="姓名" value="1"></el-option>
          <el-option label="用户名" value="2"></el-option>
        </el-select>
        <i slot="prefix" class="el-input__icon el-icon-search"></i>
      </el-input>
      <el-select v-model="stuType" placeholder="请选择学员类型" class="tb-smselect">
        <el-option
          v-for="item in stuOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-button @click="search" type="primary" class="tb-button">搜索</el-button>
      <el-button @click="reset" type="primary" class="tb-button">重置</el-button>
      <el-button @click="add" type="info" class="tb-button">新增学员</el-button>
    </el-row>
    <div class="table-container">
      <el-table
        :data="dataList"
        border
        fit
        highlight-current-row
        class="tb-list"
      >
        <el-table-column label="用户名" align="center" prop="loginName"></el-table-column>
        <el-table-column label="姓名" align="center" prop="name"></el-table-column>
        <el-table-column label="是否试听" align="center">
          <template slot-scope="scope">
            <span v-if="Boolean(scope.row.is_try)">是</span>
            <span v-else>否</span>
          </template>
        </el-table-column>
        <el-table-column label="是否过期" align="center">
          <template slot-scope="scope">
            <span v-text="PastTime(scope.row.expires_time)"></span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.createTime | formatDate}}</span>
          </template>
        </el-table-column>
        <el-table-column label="来源" align="center" prop="source"></el-table-column>
        <el-table-column label="状态" align="center">
          <template slot-scope="scope">
            <span v-text="getStateNum(scope.row.state)" :style="'color'+':'+getStateNumColor(scope.row.state)"></span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="250">
          <template slot-scope="scope">
            <el-button size="small" type="primary" plain @click="edit(scope.row)">编辑</el-button>
            <template v-if="scope.row.state === '1'">
              <el-button size="small" type="danger" plain @click="stop(scope.row)">停用</el-button>
            </template>
            <template v-else>
              <el-button size="small" type="success" plain @click="start(scope.row)">启用</el-button>
            </template>
            <el-button size="small" type="danger" plain @click="del(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.currentPage"
        :page-sizes="pagination.pageSizes"
        :page-size="pagination.pageSize"
        :layout="pagination.layout"
        :total="pagination.total"
      ></el-pagination>
    </div>
    <!-- add & edit dialog -->
    <add-student
      :show="stuDialog"
      :stuData="stuData"
      @close="close"
      @addStuData="addStuData"
      @editStuData="editStuData"
    ></add-student>
  </el-row>
</template>

<script>
import addStudent from '@/components/system/addStudent'
import getStateNum from '@/mixins/getStateText'
import getStateNumColor from '@/mixins/getStateColor'
import { getStuList, addStu, editStu } from '@/api/system/student'
import { delUser, enableUser } from '@/api/system/public'
import { formatTate } from '@/utils/formatDate'

export default {
  components: {
    addStudent
  },
  mixins: [getStateNum, getStateNumColor],
  data () {
    return {
      inputVal: '',
      select: '1',
      stuType: null,
      stuOptions: [
        {
          label: '全部学员',
          value: null
        },
        {
          label: '正式学员',
          value: false
        },
        {
          label: '试听学员',
          value: true
        }
      ],
      stuDialog: false,
      dataList: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      stuData: null
    }
  },
  created () {
    this.getListStudent()
  },
  methods: {
    // 获取学员列表
    async getListStudent () {
      const res = await getStuList({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        loginName: this.select === '2' ? this.inputVal : '',
        name: this.select === '1' ? this.inputVal : '',
        isTry: this.stuType
      })
      this.dataList = res.body.list
      this.pagination.total = res.body.total
    },

    // 分页
    handleSizeChange (val) {
      this.pagination.pageSize = val
      this.getListStudent()
    },
    handleCurrentChange (val) {
      this.pagination.currentPage = val
      this.getListStudent()
    },

    // 搜索/重置
    search () {
      this.pagination.currentPage = 1
      this.getListStudent()
    },
    reset () {
      this.select = '1'
      this.inputVal = ''
      this.stuType = null
    },

    // 新增/编辑
    add () {
      this.stuDialog = true
    },
    edit (row) {
      this.stuData = row
      this.stuDialog = true
    },

    // 停用学员
    async stop (row) {
      await enableUser({
        userId: row.userId,
        status: 2
      })
      window.$msg('停用成功')
      this.getListStudent()
    },

    // 启用学员
    async start (row) {
      await enableUser({
        userId: row.userId,
        status: 1
      })
      window.$msg('启用成功')
      this.getListStudent()
    },

    // 新增学员
    async addStuData (data) {
      let addStuData = {
        campusId: data.campus_id,
        loginName: data.loginName,
        pwd: data.pwd,
        name: data.name,
        tel: data.tel,
        isTry: data.stuType === '正式学员' ? Boolean(0) : Boolean(1),
        expiresTime: data.stuType === '正式学员' ? null : data.expiresTime
      }
      await addStu({
        ...addStuData
      })
      window.$msg('添加成功')
      this.close()
      this.getListStudent()
    },

    // 编辑学员
    async editStuData (data) {
      let editStuData = {
        id: data.userId,
        campusId: data.campus_id,
        loginName: data.loginName,
        name: data.name,
        password: data.pwd || null,
        tel: data.tel,
        isTry: data.stuType === '正式学员' ? Boolean(0) : Boolean(1),
        expires_time: data.stuType === '正式学员' ? null : data.expiresTime
      }
      await editStu({
        ...editStuData
      })
      window.$msg('编辑成功')
      this.close()
      this.getListStudent()
    },

    // 删除学员
    del (row) {
      this.$confirm('是否删除当前学员', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          await delUser({
            userId: row.userId
          })
          window.$msg('删除成功')
          this.getListStudent()
        })
        .catch(() => {
          return false
        })
    },

    // 关闭
    close () {
      this.stuDialog = false
      this.stuData = null
    },

    PastTime (time) {
      let expiresTime = formatTate(time)
      expiresTime = Date.parse(new Date(expiresTime))
      let presentTime = formatTate(new Date())
      presentTime = Date.parse(presentTime)
      if (Number(expiresTime) > Number(presentTime)) {
        return '否'
      } else {
        return '是'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../style/table.scss";
</style>
