import $axios from '@/utils/request'

// 删除用户
export function delUser (data) {
  const url = '/user/del'
  return $axios.fPost(url, data)
}

// 启/停用户
export function enableUser (data) {
  const url = '/user/setEnable'
  return $axios.fPost(url, data)
}

// 获取用户信息
export function getUserInfo (data) {
  const url = '/user/info'
  return $axios.fPost(url, data)
}
