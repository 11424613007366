<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    @open="openDialog"
    width="50%"
    top="7vh"
    >
    <div slot="title" class="dia-tit">
      <i></i>
      <span v-text="StuDialogTitle"></span>
    </div>
    <!-- stuData：{{stuData}} -->
    <el-form ref="formData" :model="formData" :rules="rules" label-width="120px">
      <el-row>
        <el-form-item label="用户名：" prop="loginName">
          <el-input v-model="formData.loginName" placeholder="请输入用户名" :disabled="isWeb"></el-input>
        </el-form-item>
        <el-form-item label="姓名：" prop="name">
          <el-input v-model="formData.name" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <template v-if="this.stuData === null">
          <el-form-item label="初始密码：" prop="pwd">
            <el-input v-model="formData.pwd" placeholder="请输入初始密码（密码包含大小写字母、特殊符号、数字三种即可）" show-password auto-complete="new-password"></el-input>
          </el-form-item>
        </template>
        <template v-else>
          <el-form-item label="修改密码：">
            <el-input v-model="formData.pwd" placeholder="请输入新密码、为空密码不变" show-password auto-complete="new-password"></el-input>
          </el-form-item>
        </template>
        <el-form-item label="手机号：">
          <el-input v-model="formData.tel" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item label="学员类型">
          <el-radio-group v-model="formData.stuType" size="mini" @input="seleType" :disabled="isWeb">
            <el-radio-button label="试听学员"></el-radio-button>
            <el-radio-button label="正式学员"></el-radio-button>
          </el-radio-group>
          <el-date-picker
            style="margin-left: 25px;"
            v-if="formData.stuType === '试听学员'"
            v-model="expiresTime"
            type="datetime"
            :disabled="isWeb"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="试听学员账户失效日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="所属校区：">
          <el-select v-model="formData.campus_id" placeholder="请选择校区" @change="getSchoolValue">
            <el-option
              v-for="item in schoolArray"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-row>
    </el-form>
    <el-row slot="footer">
      <el-button type="primary" @click="saveForm" class="dialog-btn">确 定</el-button>
      <el-button @click="close" type="info" class="dialog-btn">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
import { formatTate } from '@/utils/formatDate'
import { getCampusAll } from '@/api/senate/campus'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    stuData: {
      default: () => {
        return {}
      },
      type: Object
    },
    isWeb: {
      default: false,
      type: Boolean
    },
    stuTitle: {
      default: '',
      type: String
    }
  },
  watch: {
    show () {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.formData.resetFields()
        }
      }
    }
  },
  data () {
    return {
      StuDialogTitle: '',
      formData: {
        stuType: '正式学员'
      },
      expiresTime: '',
      schoolArray: [],
      rules: {
        loginName: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        pwd: [{ required: true, message: '请输入密码', trigger: 'blur' }]
        // campus_id: [{ required: true, message: '请选择校区', trigger: 'blur' }]
      }
    }
  },
  methods: {
    openDialog () {
      this.formData = {
        stuType: '正式学员'
      }
      this.expiresTime = ''
      if (this.stuData === null) {
        this.StuDialogTitle = '新增学员'
      } else {
        if (!this.stuTitle) {
          this.StuDialogTitle = '编辑学员'
        } else {
          this.StuDialogTitle = this.stuTitle
        }
        this.formData = Object.assign({}, this.stuData)
        if (this.stuData.is_try === 0) {
          this.formData.stuType = '正式学员'
        } else {
          this.formData.stuType = '试听学员'
          let expiresTime = formatTate(this.stuData.expires_time)
          this.expiresTime = expiresTime
        }
      }
      this.getCampusAll()
    },
    close () {
      this.$emit('close')
    },
    getCampusAll () {
      getCampusAll().then(res => {
        if (res.state === 'success') {
          this.schoolArray = res.body
        }
      })
      // fPost("/campus/getCampusAll", {})
      //   .then(res => {
      //     if (res.state === 'success') {
      //       this.schoolArray = res.body
      //     } else {
      //     }
      //   })
      //   .catch(err => {
      //   })
    },
    getSchoolValue (val) {
      this.formData.campus_id = val
    },
    saveForm () {
      this.$refs['formData'].validate(valid => {
        if (valid) {
          if (this.stuData === null) {
            this.$emit('addStuData', { ...this.formData, expiresTime: this.expiresTime })
          } else {
            this.$emit('editStuData', { ...this.formData, expiresTime: this.expiresTime })
          }
        }
      })
    },
    seleType () {
      this.$forceUpdate()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../style/dialog.scss';
</style>
